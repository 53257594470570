export const mechelenBoundary = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Mechelen Boundary"
      },
      "geometry": {
        "coordinates": [
          [
            [4.467468618534838, 51.072940998411866],
            [4.462563166132611, 51.07382093776545],
            [4.457239090949912, 51.07328960368625],
            [4.450793592712813, 51.071527102512874],
            [4.44505516430246, 51.07328195120107],
            [4.442390827554476, 51.07689322794997],
            [4.43888324780886, 51.07865780530588],
            [4.4346897945484045, 51.07847360187074],
            [4.430476812987365, 51.07663172668097],
            [4.427956486439086, 51.07425339529959],
            [4.422631203889097, 51.073373244823046],
            [4.419817452301544, 51.07346668427138],
            [4.421783801993826, 51.07152734494508],
            [4.424720458994187, 51.06959287603985],
            [4.424996222569092, 51.068185631758894],
            [4.426538769842921, 51.067128344793275],
            [4.41952332178019, 51.05999467324949],
            [4.40467011354346, 51.061402973589026],
            [4.3980943152171506, 51.05875795454],
            [4.398792161476138, 51.056908459894316],
            [4.390114675339561, 51.05320726229942],
            [4.394873478950927, 51.04721682708154],
            [4.380570613875705, 51.04712917359075],
            [4.37159910269682, 51.04122456294402],
            [4.375539052223729, 51.01160827560267],
            [4.388403192924017, 51.00532071886357],
            [4.388256859851992, 50.998354309239886],
            [4.397794432050972, 50.98883091104733],
            [4.420771656192613, 50.99456300801208],
            [4.4514576598783435, 51.00761468413927],
            [4.467573700996269, 50.9913858549715],
            [4.484809454608978, 50.99032795334173],
            [4.489576660087806, 50.99614891975395],
            [4.486626107991299, 51.005673340639504],
            [4.49279491671723, 51.00831874196652],
            [4.52907931807556, 50.992358432202764],
            [4.552621263496405, 51.00331048937443],
            [4.536497868179993, 51.02006903643223],
            [4.510426575584063, 51.02968392748872],
            [4.514623554273243, 51.034878599691126],
            [4.507197308145464, 51.040606708531385],
            [4.497243742430953, 51.04774518720393],
            [4.494020080848287, 51.0528557952758],
            [4.48336833731949, 51.05461699585561],
            [4.476074881756688, 51.053651130813506],
            [4.467946747243815, 51.05065372256817],
            [4.463461999977852, 51.05743817061634],
            [4.468508681994706, 51.06122631882687],
            [4.465842544210233, 51.06466419485392],
            [4.467468618534838, 51.072940998411866]
          ]
        ],
        "type": "Polygon"
      }
    }
  ]
} as const;