// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://webocybzloqwnyxpquam.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndlYm9jeWJ6bG9xd255eHBxdWFtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM2MDczNDMsImV4cCI6MjA0OTE4MzM0M30.mi3mWYq3hk2CqpFf8Ba9x5VT-Jv2H8pxv3np7MDOKXQ";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);